var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loader',{attrs:{"retry":_vm.initialize}},[(_vm.item && _vm.intakeFormSubmissions)?_c('div',[_c('q-toolbar',{staticClass:"bg-secondary"},[_c('q-btn',{attrs:{"flat":"","label":"Add to Project","text-color":"white","icon":"add"},on:{"click":function($event){_vm.addToProjectDialog = true}}}),_c('q-separator',{attrs:{"dark":"","vertical":"","inset":""}})],1),_c('q-input',{staticClass:"text-subtitle2 q-pa-md",attrs:{"borderless":"","readonly":"","filled":"","label":"Projects for","value":((_vm.item.firstName) + " " + (_vm.item.lastName) + " (" + (_vm.item.dateOfBirth.split('T')[0]) + ")")}}),(_vm.intakeFormSubmissions.length === 0 && _vm.referrals.length === 0)?_c('div',{staticClass:"q-pa-lg text-center"},[_vm._v(" No items found... ")]):_c('div',{staticClass:"q-pa-md row items-start q-col-gutter-md flex"},[_vm._l((_vm.intakeFormSubmissions),function(s){return _c('div',{key:s.id,staticClass:"col-12 col-md-4 col-lg-3"},[_c('q-card',{staticClass:"fit flex-card"},[_c('q-card-section',[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(s.project.title))]),_c('div',{staticClass:"text-subtitle2"},[_vm._v(" "+_vm._s(s.startDate.split("T")[0])+" "),(s.endDate)?_c('span',[_vm._v("- "+_vm._s(s.endDate.split("T")[0]))]):_vm._e()])]),_c('q-separator'),_c('q-card-actions',{attrs:{"align":"center"}},[_c('q-btn',{attrs:{"icon":"download","flat":"","loading":_vm.$proxy.download},on:{"click":function($event){_vm.$runAsync('download', function () { return _vm.downloadIntakeForm(s); })}}}),_c('q-btn',{ref:"test",refInFor:true,attrs:{"label":"Edit Submission","flat":"","loading":_vm.$proxy.loadingSubmissions},on:{"click":function($event){_vm.$runAsync('loadingSubmissions', function () { return _vm.loadSubmission(s.questionnaireSubmissionId); }
                )}}}),(
                !s.endDate || new Date(Date.parse(s.endDate)) > new Date()
              )?_c('q-btn',{attrs:{"loading":_vm.$proxy.deletingFromProject,"label":"Remove","color":"red-4"},on:{"click":function($event){_vm.$runAsync('deletingFromProject', function () { return _vm.endProject(s.id); })}}}):_vm._e()],1)],1)],1)}),_vm._l((_vm.referrals),function(s){return _c('div',{key:s.id,staticClass:"col-12 col-md-4 col-lg-3 flex"},[_c('q-card',{staticClass:"fit flex-card"},[_c('q-card-section',{staticClass:"bg-red-5 text-white"},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(s.project.title))]),_c('div',{staticClass:"text-subtitle2"},[_vm._v(" "+_vm._s(s.date.split("T")[0])+" ")])]),_c('q-separator'),_c('q-card-actions',{staticClass:"bg-red-6",attrs:{"align":"center"}},[(
                s.project.intakeForm &&
                  _vm.item.prescreening &&
                  _vm.item.prescreening.id
              )?_c('q-btn',{ref:"test",refInFor:true,attrs:{"label":"Take","color":"red-5"},on:{"click":function($event){return _vm.goToIntake(_vm.item.prescreening.id, _vm.item.id, s.project.id)}}}):(!s.project.intakeForm)?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" Project has no Intake Form ")]):_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" Beneficiary has no Prescreening ")])],1)],1)],1)})],2),_c('q-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.addToProjectDialog),callback:function ($$v) {_vm.addToProjectDialog=$$v},expression:"addToProjectDialog"}},[_c('q-card',{staticStyle:{"min-width":"300px"}},[_c('q-card-section',{staticClass:"text-h6"},[_vm._v(" Add to a project ")]),_c('q-separator'),_c('q-card-section',{staticStyle:{"max-height":"50vh"}},[_c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"col-12"},[_c('entity-select',{attrs:{"filled":"","label":"Select a project","provider":_vm.$service.providers.intakeFormProjects,"optionLabel":function (i) { return (i ? i.title : ''); },"oDataArgs":{
                  columnFilters: [
                    {
                      column: 'Programme/Type',
                      op: 'Equals',
                      value: '\'Implementation\''
                    }
                  ]
                }},model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}})],1)])]),_c('q-separator'),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"Cancel","flat":""}}),_c('q-btn',{attrs:{"color":"primary","label":"Add","disable":_vm.selectedProject == null},on:{"click":function($event){return _vm.goToIntake(_vm.item.prescreening.id, _vm.item.id, _vm.selectedProject.id)}}})],1)],1)],1),_c('q-dialog',{attrs:{"scrollable":"","persistent":""},model:{value:(_vm.showResults),callback:function ($$v) {_vm.showResults=$$v},expression:"showResults"}},[(_vm.submission)?_c('questionnaire-submit',{attrs:{"questionnaireId":_vm.submission.id,"inDialog":true},on:{"save":function($event){_vm.showResults = false}}}):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }