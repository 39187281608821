









































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HouseholdMembersActions from "@/data/columns/HouseholdMembersActions";
import ModelTable from "@/components/ModelTable.vue";
import AddHouseholdMemberActionForm from "@/components/forms/AddHouseholdMemberActionForm.vue";
import HouseholdMemberActionSessionsTable from "@/components/HouseholdMemberActionSessionsTable.vue";
import AddHouseholdMemberSessionForm from "@/components/forms/AddHouseholdMemberSessionForm.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import Loader from "@/components/Loader.vue";
import { saveAs } from "file-saver";

import QuestionnaireSubmit from "@/components/forms/QuestionnaireSubmit.vue";
@Component({
  components: {
    ModelTable,
    AddHouseholdMemberActionForm,
    HouseholdMemberActionSessionsTable,
    AddHouseholdMemberSessionForm,
    DynamicForm,
    EntitySelect,
    QuestionnaireSubmit,
    Loader
  }
})
export default class HouseholdMemberProjects extends Vue {
  @Prop()
  public id!: string;
  private item: any = null;
  addToProjectDialog = false;
  intakeFormSubmissions: any[] | null = null;
  selectedProject: any = null;
  submission: any = null;
  answers: any = null;
  showResults = false;
  referrals: any[] = [];

  async initialize() {
    this.item = await this.$service.providers.householdMembers.fetchItemAsync(
      this.id
    );

    const ref = (
      await this.$service.providers
        .householdMembersReferrals(this.id)
        .fetchItemsAsync()
    ).items;
    this.intakeFormSubmissions = (
      await this.$service.providers
        .householdMemberIntakeFormSubmissions(this.id)
        .fetchItemsAsync()
    ).items;
    this.referrals = ref.filter(
      p =>
        p.project &&
        this.intakeFormSubmissions?.every(
          (s: any) => s.project.id !== p.project.id
        )
    );
  }

  goToIntake(prescreeningId: string, beneficiaryId: string, projectId: string) {
    this.$router.push({
      name: "IntakeForm",
      params: {
        prescreeningId: prescreeningId,
        beneficiaryId: beneficiaryId,
        projectId: projectId
      }
    });
  }

  async loadSubmission(id: string) {
    const q = await this.$service.providers.questionnaireSubmissions.fetchItemAsync(
      id
    );
    this.submission = q;

    this.showResults = true;
  }

  async downloadIntakeForm(item: any) {
    const utc = new Date().toJSON().slice(0, 10);
    try {
      saveAs(await this.$service.downloadProjectSubmission(item.id), 
        `${item.project.title}_${this.item.firstName}_${this.item.lastName}_${utc}.xlsx`);
    } catch (e) {
      console.error(e);
    }
  }

  async endProject(id: string) {
    await this.$service.crud.intakeFormSubmissions.postAsync(
      "End",
      JSON.stringify(id)
    );
    await this.initialize();
  }
}
